<template>
  <div class="country-select">
    <input
      @click="showDialog = true"
      type="text"
      v-model="countryName"
      :placeholder="placeholder"
      readonly
      class="form-item-ele"
    />
    <div class="select-contianer" v-show="showDialog">
      <div class="select-header">
        <div @click="showDialog = false">
          <img
            src="../assets/image/back.png"
            style="width: 22px; padding: 3px 0px"
          />
          返回
        </div>
      </div>
      <div class="padding-10" style="margin: 10px 0px">
        <input
          type="text"
          placeholder="请输入国家名称"
          class="form-item-ele"
          v-model="nationName"
          @input="getSearchValue"
        />
      </div>
      <div class="country-list padding-10" style="margin-top: 25px;">
        <div class="group-title"><span></span>热门国家</div>
        <ul class="country-list-item">
          <li
            @click="setCurrentValue(item.id, item.dictName)"
            v-for="item in popular"
            :key="item.id"
          >
            <span>{{ item.dictName }}</span>
          </li>
        </ul>
        <div class="group-title"><span></span>选择国家</div>
        <ul class="country-list-item">
          <li
            @click="setCurrentValue(item.id, item.dictName)"
            v-for="item in nationList"
            :key="item.id"
          >
            <span>{{ item.dictName }}</span>
          </li>
        </ul>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>
<script>
import { addressInfo } from "../utils/apis/apis.js";
export default {
  name: "countrySelect",
  props: ["value", "placeholder"],
  data() {
    return {
      showDialog: false,
      countryValue: "",
      nationName: "",
      countryName: "",
      nationList: [],
      popular: [],
      baseList: [],
    };
  },
  created() {
    this.countryValue = this.value;

    this.getNations();
  },
  watch: {
    value(val) {
      this.countryValue = val;
      this.getCurrentValue();
    },
  },
  methods: {
    getCurrentValue() {
      if (this.countryValue) {
        let selected = this.baseList.filter((item) => {
          return item.id === this.countryValue;
        })
        if (selected.length) {
          this.countryName = selected[0].dictName
        }
      }
    },
    setCurrentValue(value, name) {
      this.showDialog = false;
      this.countryValue = value;
      this.countryName = name;
      this.$emit("blur", {
        target: {
          value: this.countryValue,
          name: this.countryName,
        },
      });
    },
    getSearchValue(event) {
      this.nationList = this.baseList.filter(
        (item) => item.dictName.indexOf(event.target.value) > -1
      );
    },
    getNations() {
      this.$reqGet(addressInfo.nationList).then((res) => {
        if (res.data.code === 200) {
          this.nationList = res.data.data.nopopular;
          this.popular = res.data.data.popular;
          this.baseList = res.data.data.nopopular.map((item) => item);
          this.getCurrentValue();
        }
      });
    },
  },
};
</script>
<style scoped>
.country-select {
  margin-left: -8px;
}
.form-item-ele {
  height: 35px;
  width: calc(100% - 8px);
  padding-left: 8px;
}
.select-header {
  background: #04b8ed;

  color: #fff;
}
.select-header div {
  display: flex;
  align-items: center;
}
.select-contianer {
  background: #fff;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.group-title {
  height: 22px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0px;
}
.group-title span {
  width: 3px;
  height: 100%;
  margin-right: 8px;

  background-color: #04b8ed;
}
.country-list {
  overflow-y: auto;
  overflow-wrap: hidden;
  height: calc(100vh - 100px);
}
.country-list-item {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.country-list-item li {
  width: 50%;
  text-align: center;
}
.country-list-item li span {
  display: block;
  border: 1px solid #dedede;
  margin: 5px;
  padding: 6px 0px;
  font-size: 14px;
}
</style>